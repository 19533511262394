import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import loginRouter from './login'
import dashboardRouter from './dashboard'
import partnerRouter from './partner'
import clinicRouter from './clinic'
import serviceRouter from './service'
import faqRouter from './faq'
import directoryRouter from './directory'
import profileRouter from './profile'

Vue.use(VueRouter)

const routes = [
  loginRouter,
  dashboardRouter,
  partnerRouter,
  clinicRouter,
  serviceRouter,
  faqRouter,
  directoryRouter,
  profileRouter,
  { path: '*', redirect: '/login' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'PartnerDetail') {
    store.commit('SET_BREADCRUMB', to.meta.breadcrumb ?? [])
  }

  if (!to.meta.needAuth) return next()

  const hasToken = !!store.getters.authHeader
  if (!hasToken) return next({ name: 'Login' })

  if (to.name === 'Login') return next({ name: 'PartnerList' })

  if (!store.state.userData.id) await store.dispatch('getUserData')
  next()
})

export default router
