import Vue from 'vue'

const customFunctions = {}
customFunctions.install = function (Vue) {
  Vue.prototype.validate = function (name) {
    const { $dirty, $error } = this.$v.form[name]
    return $dirty ? !$error : null
  }
}

Vue.use(customFunctions)

export default customFunctions
