import Layout from '@/layouts/login'

const loginRouter = {
  path: '/login',
  component: Layout,
  children: [
    {
      name: 'Login',
      path: '',
      component: () => import('@/views/login'),
      meta: {
        needAuth: false,
        rootMenu: 'Login'
      }
    },
    {
      name: 'LoginConfirm',
      path: 'confirm',
      component: () => import('@/views/login/confirm'),
      meta: {
        needAuth: true,
        rootMenu: 'Login'
      }
    }
  ]
}

export default loginRouter
