import apiService from '@/common/api.service'

export function getUserData () {
  return apiService({
    url: '/api/user',
    method: 'get'
  })
}

export function getUserProfileData () {
  return apiService({
    url: '/api/user/partner',
    method: 'get'
  })
}

export function updateUserOwnerData (data) {
  return apiService({
    url: '/api/user/owner',
    method: 'patch',
    data
  })
}

export function updateUserCorporateData (data) {
  return apiService({
    url: '/api/user/corporate',
    method: 'patch',
    data
  })
}
export function updateUserContact (data) {
  return apiService({
    url: '/api/user/contact',
    method: 'patch',
    data
  })
}
export function resetPassword (data) {
  return apiService({
    url: '/api/user/reset',
    method: 'patch',
    data
  })
}
