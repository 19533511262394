import Layout from '@/layouts/main'

const partnerRouter = {
  path: '/partner',
  component: Layout,
  children: [
    {
      name: 'PartnerList',
      path: '',
      component: () => import('@/views/partner'),
      meta: {
        needAuth: true,
        rootMenu: 'Partner',
        breadcrumb: [
          {
            text: 'Partner List'
          }
        ]
      }
    },
    {
      name: 'PartnerCreate',
      path: 'create',
      component: () => import('@/views/partner/create'),
      meta: {
        needAuth: true,
        rootMenu: 'Partner',
        breadcrumb: [
          {
            text: 'Add New Partner'
          }
        ]
      }
    },
    {
      name: 'PartnerDetail',
      path: ':id/detail',
      component: () => import('@/views/partner/detail'),
      props: route => ({ id: parseInt(route.params.id) }),
      meta: {
        needAuth: true,
        rootMenu: 'Partner',
        breadcrumb: [
          {
            text: 'Partner List',
            to: { name: 'PartnerList' }
          },
          {
            text: 'Inc'
          }
        ]
      }
    }
  ]
}

export default partnerRouter
