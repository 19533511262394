import axios from 'axios'
import router from '@/router'
import store from '@/store'

const apiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000
})
apiService.interceptors.request.use(
  config => {
    config.headers.Authorization = store.getters.authHeader
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

apiService.interceptors.response.use(
  response => response,
  error => {
    const needAuth = router.history.current.meta.needAuth
    switch (error.response?.status) {
      case 401: {
        if (!needAuth) break
        router.push({ name: 'Login' })
        break
      }
      default: {
        break
      }
    }
    return Promise.reject(error)
  }
)

export default apiService
