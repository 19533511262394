<template>
  <header class="header d-flex justify-content-between align-items-center mb-8">
    <div class="header-breadcrumb">
      <b-breadcrumb :items="breadcrumb" :active-class="'h1'" />
    </div>

    <div id="header-toolbar" class="position-relative">
      <ul class="header-toolbar list-unstyled mb-0">
        <li>
          <b-link :class="{ active: currentItem === 'user' }" @click="currentItem = 'user'">
            <font-awesome-icon :icon="['fas', 'user-circle']" size="lg" fixed-width />
          </b-link>
        </li>
        <li>
          <b-link :class="{ active: currentItem === 'notify' }" @click="currentItem = 'notify'">
            <font-awesome-icon :icon="['fas', 'bell']" size="lg" fixed-width />
          </b-link>
        </li>
      </ul>

      <div v-if="currentItem" class="header-toolbar-content">
        <component :is="toolbarContentComponent" @hidden="hiddenItem" @openLanguageModal="openLanguageModal" />
      </div>
    </div>

    <language-modal ref="languageModal" />
  </header>
</template>

<script>
import User from './headerUser'
import Notify from './headerNotify'
import LanguageModal from './languageModal.vue'
import { getUserProfileData } from '@/api/user'

export default {
  name: 'Header',
  components: {
    User,
    Notify,
    LanguageModal
  },
  data () {
    return {
      currentItem: null
    }
  },
  computed: {
    toolbarContentComponent () {
      switch (this.currentItem) {
        case 'user':
          return 'User'
        default:
          return 'Notify'
      }
    },
    breadcrumb () {
      const breadcrumb = this.$store.state.breadcrumb
      return [{ text: this.$store.state.corporate_name, to: { name: 'Dashboard' } }, ...breadcrumb]
    }
  },
  mounted () {
    this.getUserProfileData()
    window.document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    window.document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (e) {
      const isMenu = e.path.some(el => el.id === 'header-toolbar')
      if (!isMenu) this.currentItem = null
    },
    hiddenItem () {
      this.currentItem = null
    },
    openLanguageModal () {
      this.$refs.languageModal.show()
    },
    getUserProfileData () {
      getUserProfileData().then(res => {
        this.$store.commit('SET_CORPORATENAME', res.data.data.corporate_name)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.header {
  &-breadcrumb {
    margin-left: 6.5rem;
  }

  .breadcrumb-item,.breadcrumb {
    color: $primary;
    font-size: 2.25rem;
    span,a {
      color: $primary;
      font-size: 2.25rem;
    }
  }

  &-toolbar {
    display: flex;
    background-color: $primary;
    border-radius: 1.25rem;
    position: relative;
    box-shadow:
      -10px -10px 20px 0 #fff,
      10px 10px 20px 0 rgba(174, 174, 192, 0.4),
      inset 4px 4px 6px 0 rgba(255, 255, 255, 0.4);
    padding: 0.75rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      background: linear-gradient(-42deg, #0077c6 0%,#00a7e0 95%);
      color: #fff;
      box-shadow: none;
      margin: 0 0.75rem;

      &:hover {
        background: #f0f0f3;
        color: $primary;
      }

      &.active {
        background: #f0f0f3;
        color: $primary;
      }
    }

    &-content {
      position: absolute;
      top: 100%;
      right: 0;
      border-radius: 0.75rem;
      backdrop-filter: blur(10px);
      box-shadow: 10px 10px 20px 0 rgba(174, 174, 192, 0.4), inset 2px 2px 6px 0 rgba(255, 255, 255, 0.8);
      background-color: rgba(242, 243, 247, 0.4);
      z-index: 10;
      padding: 1rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
