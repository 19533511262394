const TOKEN_KEY = 'token'

export const getToken = () => {
  const cookies = window.document.cookie.split(';')
  const tokenCookie = cookies.find(cookie => cookie.trim().split('=')[0] === TOKEN_KEY)
  return tokenCookie?.trim().split('=')[1]
}

export const saveToken = token => {
  const DAY_30 = 60 * 60 * 24 * 30
  window.document.cookie = `${TOKEN_KEY}=${token}; path=/; max-age=${DAY_30}`
}

export const removeToken = () => {
  window.document.cookie = `${TOKEN_KEY}=null; path=/; max-age=-1`
}

export default { getToken, saveToken, removeToken }
