<template>
  <b-modal
    id="language-modal"
    title="Change Language"
    size="lg"
    hide-header-close
    hide-footer
    centered
  >
    <div class="p-4 mx-4 mb-8">
      <b-row>
        <b-col cols="4">
          <a href="#" class="language-item d-block text-dark text-decoration-none p-4" @click="changeLanguage">
            English
          </a>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'LanguageModal',
  methods: {
    show () {
      this.$bvModal.show('language-modal')
    },
    hide () {
      this.$bvModal.hide('language-modal')
    },
    changeLanguage () {
      this.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.language-item:hover {
  background-color: rgba(210, 226, 246, 0.2);
}
</style>
