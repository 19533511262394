<template>
  <div class="user">
    <h2 class="m-4">
      Hi, {{ userData.name }}
    </h2>
    <b-link class="user-link d-block text-decoration-none px-4 pt-4" :to="{ name: 'Profile' }" @click="hidden">
      User Profile
    </b-link>
    <b-link class="user-link d-block text-decoration-none px-4 pt-4" @click="hidden">
      Terms of Service
    </b-link>
    <b-link class="user-link d-block text-decoration-none px-4 pt-4" @click="openLanguageModal">
      Change Language
    </b-link>
    <hr>
    <b-link class="user-link d-block text-decoration-none px-4 pb-4" @click="logout">
      Log out
    </b-link>
  </div>
</template>

<script>
export default {
  name: 'User',
  computed: {
    userData () {
      return this.$store.state.userData
    }
  },
  methods: {
    hidden () {
      this.$emit('hidden')
    },
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'Login' })
      })
    },
    openLanguageModal () {
      this.hidden()
      this.$emit('openLanguageModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.user {
  width: 22rem;

  &-link {
    color: $dark;

    &:hover {
      color: $primary;
    }
  }
}
</style>
