<template>
  <div class="notification mx-2">
    <h2 class="mb-6 mt-4">
      Notification
    </h2>

    <b-row class="px-2 scroll-list custom-scroll">
      <b-col
        v-for="(item, index) in notificationList"
        :key="index"
        cols="12"
        class="mb-4 notification-item px-2 py-2"
      >
        <h4>
          {{ item.title }}
        </h4>
        <p class="notification-content mb-2">
          {{ item.content }}
        </p>
        <span class="notification-datetime">
          {{ $dayjs(item.datetime).format("MMM DD hh:mm a") }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getNotificationList } from '@/api/notification'

export default {
  name: 'Notify',
  data () {
    return {
      notificationList: []
    }
  },
  created () {
    this.getNotificationList()
  },
  methods: {
    getNotificationList () {
      getNotificationList().then(res => {
        this.notificationList = res.data.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~@/assets/style/variables";

.notification {
  width: 18rem;

  &-content {
    font-size: 14px;
  }

  &-datetime {
    font-size: 12px;
    font-weight: 500;
    color: $primary;
  }
  &-item {
    border-radius: 8px;
    &:hover {
      border-radius: 8px;
      background-color: rgba(210, 226, 246, 0.2);
    }
  }
}
.scroll-list{
  max-height: 650px;
  height: 300px;
  overflow-y: auto;
}
</style>
