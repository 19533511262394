import Layout from '@/layouts/main'

const dashboardRouter = {
  path: '/dashboard',
  component: Layout,
  children: [
    {
      name: 'Dashboard',
      path: '',
      component: () => import('@/views/dashboard'),
      meta: {
        needAuth: true,
        rootMenu: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard'
          }
        ]
      }
    }
  ]
}

export default dashboardRouter
