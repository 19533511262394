import Layout from '@/layouts/main'

const clinicRouter = {
  path: '/clinic',
  component: Layout,
  children: [
    {
      name: 'ClinicList',
      path: '',
      component: () => import('@/views/clinic'),
      meta: {
        needAuth: true,
        rootMenu: 'Clinic',
        breadcrumb: [
          {
            text: 'Customer List'
          }
        ]
      }
    },
    {
      name: 'ClinicCreate',
      path: 'create',
      component: () => import('@/views/clinic/create'),
      meta: {
        needAuth: true,
        rootMenu: 'Clinic',
        breadcrumb: [
          {
            text: 'Add New Clinic'
          }
        ]
      }
    },
    {
      name: 'ClinicDetail',
      path: ':id/detail',
      component: () => import('@/views/clinic/detail'),
      props: route => ({ id: parseInt(route.params.id) }),
      meta: {
        needAuth: true,
        rootMenu: 'Clinic',
        hasBackBtn: true,
        breadcrumb: [
          {
            text: 'Clinic Profile'
          }
        ]
      }
    }
  ]
}

export default clinicRouter
