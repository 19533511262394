import Layout from '@/layouts/main'

const profileRouter = {
  path: '/profile',
  component: Layout,
  children: [
    {
      name: 'Profile',
      path: '',
      component: () => import('@/views/profile'),
      meta: {
        needAuth: true,
        rootMenu: 'Profile',
        breadcrumb: [
          {
            text: 'Profile'
          }
        ]
      }
    }
  ]
}

export default profileRouter
