import apiService from '@/common/api.service'

export function login (data) {
  return apiService({
    url: '/api/auth/login',
    method: 'post',
    data
  })
}

export function logout () {
  return apiService({
    url: '/api/auth/logout',
    method: 'post'
  })
}
